import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import Fade from "react-reveal/Fade"
import Block from "../../components/common/Block/Block.js"
import CTA from "../../components/common/CTA.js"
import FeatureCards from "../../components/common/Feature-card/FeatureCards.js"
import Frame from "../../components/common/frame"
import WistiaVideoOnclickEmbed from "../../components/common/wistia-video-onclick-embed.js"
import DoubleNavbar from "../../components/DoubleNavbar.js"
import "../../styles/pages/home-2024.scss"
import "../../styles/pages/monica-ai.scss"
import { SliderTestimonialCard } from "../index.js"

const MonicaAi = () => {
  useEffect(() => {
    const pageURL = window.location.href.split("#")[1]
    if (pageURL) {
      const element = document.getElementById(pageURL)
      if (element) {
        element?.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [])

  const [playVideo, setPlayVideo] = useState(false)
  const [height, setHeight] = useState(0)
  const videoRef = useRef()
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (videoRef.current) {
        setHeight(videoRef.current.clientHeight)
      }
    })
    if (videoRef.current) {
      observer.observe(videoRef.current)
    }
    return () => observer.disconnect()
  }, [])

  const data = useStaticQuery(graphql`
    query MonicaAiQuery {
      SuperOps {
        pages(where: { title: "Monica AI" }) {
          seo {
            description
            title
            image {
              url
            }
          }

          heroSection {
            tag
            tagLink
            images {
              id
              url
            }
            heroImage {
              url
            }
            content {
              html
            }
            heading {
              html
            }
          }

          newField {
            ... on SuperOps_Card {
              __typename
              id
              cardType
              title
              heading
              text
              subtext
              links
              image {
                id
                url
              }
              content {
                html
              }
            }
            ... on SuperOps_Block {
              __typename
              id
              blockTextPosition
              blockLinkType
              blockTag
              blockHeading
              blockDescription
              blockImageTitle
              blockImage {
                url
              }
              blockIsIllustration
              blockIsVideo
              blockContent {
                html
              }
              linkName
              blocksExcerpt
              linkSlug
            }
            ... on SuperOps_Testimonial {
              __typename
              id
              testimonialType
              peopleList {
                position
                name
                title
                picture {
                  url
                }
                cardBgColor
                thumbnail {
                  url
                }
              }

              testimonialDatas {
                heading
                title
                name
                content
                image {
                  url
                }
              }
              testimonialSectionHeading
              testimonialTitle
              testimonialName
              testimonialDescription
              testimonialContent
              testimonialBgColor
              testimonialImage {
                url
              }
            }
            ... on SuperOps_CtaFeature {
              __typename
              id
              ctaBox {
                type
                theme
                customBackgroundColor
                heading {
                  html
                }
                description {
                  html
                }
                primaryButtonText
                primaryButtonLink
                secondaryButtonText
                secondaryButtonLink
              }
            }
          }
        }
      }
    }
  `)

  const { newField, seo, heroSection } = data.SuperOps.pages[0]

  return (
    <div className="monica-ai-container">
      <Frame seo={seo} bigFooter>
        <DoubleNavbar navType="double-nav" />

        <section className="hero-container overflow-hidden">
          <div className="hero-section position-relative">
            <video
              autoPlay
              loop
              muted
              preload="auto"
              src={heroSection?.heroImage?.url}
              className="ring"
            />
            <div className="title mx-auto text-center down-to-up-1">
              {parse(heroSection?.heading?.html)}
            </div>

            <div className="subtext down-to-up-2">
              {parse(heroSection?.content?.html)}
            </div>

            <div className="monica-img mx-auto d-flex justify-content-center down-to-up-3">
              <iframe src="https://rive.app/s/JeQqpNJeO06yHLwpSqbyqA/embed"></iframe>
            </div>
          </div>
        </section>

        <section
          id={heroSection?.tagLink}
          className="youtube-video-wrapper Layout-container-big w-100 mx-auto down-to-up-3"
        >
          <div
            className={`video-player position-relative ${
              playVideo ? "fade-out-effect" : "fade-in-effect"
            }`}
            onClick={() => setPlayVideo(true)}
            role="button"
            tabIndex={0}
          >
            <div ref={videoRef} className="position-absolute z-1">
              <WistiaVideoOnclickEmbed
                videoScreenshot={heroSection.images[0].url}
              />
            </div>
          </div>
          <iframe
            className="prelative w-100"
            style={{ height: `${height}px` }}
            src={`${heroSection?.tag}?rel=0&autoplay=${
              playVideo ? 1 : 0
            }&muted=1`}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </section>

        {newField.map((field, ind) => {
          switch (field.__typename) {
            case "SuperOps_Card": {
              if (field.cardType === "Plain_info") {
                return (
                  <div id={field?.id} className="plain-color-info">
                    <div className="Layout-container-big mx-auto">
                      <Fade bottom duration={1000}>
                        <div className="main-content text-center mb80">
                          {parse(field?.content[0]?.html)}
                        </div>
                      </Fade>
                      <FeatureCards
                        title={field?.text}
                        description={field?.subtext}
                        color={field?.links}
                        version="v1"
                        noCarousal
                      />
                    </div>
                  </div>
                )
              } else if (field.cardType === "Plain_cards") {
                return (
                  <section id={field?.id} className="plain-color-cards">
                    <div className="Layout-container-big mx-auto">
                      <Fade bottom duration={1000}>
                        {field?.content?.[0]?.html ? (
                          <div className="main-content text-center mb50">
                            {parse(field?.content?.[0]?.html)}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Fade>

                      <FeatureCards
                        title={field?.title}
                        description={field?.heading}
                        image={field?.image}
                        linkName={field?.text}
                        link={field?.subtext}
                        color={field?.links}
                        noCarousal
                      />
                    </div>
                  </section>
                )
              }
            }
            case "SuperOps_Block": {
              return (
                <>
                  <div
                    id={field?.id}
                    className={`block-parent ${
                      field?.blockTextPosition === "Right"
                        ? "text-right"
                        : "text-left"
                    }`}
                  >
                    {field?.blockHeading ? (
                      <Fade bottom duration={1000}>
                        <h3 className="Layout-container-big main-heading text-center fw-800">
                          {field?.blockHeading}
                        </h3>
                      </Fade>
                    ) : null}
                    <Block
                      image={field?.blockImage}
                      isImage={field?.blockIsIllustration}
                      isVideo={field?.blockIsVideo}
                      lgLeft={5}
                      lgRight={7}
                      tag={field?.blockTag}
                      content={field?.blockContent}
                      description={field?.blockDescription}
                      points={field?.blocksExcerpt}
                      secondaryButtonText={field?.linkName}
                      secondaryButtonLink={field?.linkSlug}
                      secondaryBtnTheme="-new"
                      containerClass
                      contentBelowImage
                      imageTitle={[""]}
                    />
                  </div>
                </>
              )
            }
            case "SuperOps_Testimonial": {
              return (
                <section
                  id={field?.id}
                  className={`slider-testimonial ${field?.testimonialType}`}
                >
                  <div className="Layout-container-big">
                    <SliderTestimonialCard
                      data={field?.peopleList[0]}
                      showQuote
                    />
                  </div>
                </section>
              )
            }
            case "SuperOps_CtaFeature": {
              return (
                <section
                  id={field?.id}
                  className="cta-black-red-stripes mx-auto"
                >
                  <CTA
                    data={[field?.ctaBox]}
                    containerClass
                    lgLeft={6}
                    lgRight={4}
                    newButton
                    isSecondaryBtn
                    newDesign
                  />
                </section>
              )
            }
          }
        })}
      </Frame>
    </div>
  )
}

export default MonicaAi
